.product-container {
  .product-description {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    flex: 1;

    .product-text {
      flex: 1;
      padding: 1%;
      h3 {
        color: var(--text-productheader-color);
      }
    }

    .product-image {
      flex: 1;
      text-align: center;

      .product_photo1,
      .product_photo3 {
        text-align: right;
        max-width: 70%;
        border-radius: 10%; /* 丸く切り抜く */
        height: auto;
      }
      .product_photo2,
      .product_photo4 {
        max-width: 70%;
        border-radius: 10%; /* 丸く切り抜く */
        height: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .product-description {
    flex-direction: column;
    margin-bottom: 0.5%;

    .product-text,
    .product-image {
      width: 100%;
      text-align: center;
    }
    .product-text {
      padding: 0;
    }
  }
}
