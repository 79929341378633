/* custom-footerのスタイル */
.custom-footer {
    bottom: 0; /* フッターを画面下部に配置 */
    width: 100%; /* フッターの幅を画面幅に合わせる */
    height: 2.5rem; /* フッターの高さを設定 */
    background-color: var(--background-dark-color); /* 背景色を黒に設定 */
    color: var(--text-light-color); /* テキスト色を白に設定 */
    display: flex; /* Flexboxを使用 */
    justify-content: center; /* 水平方向の中央寄せ */
    align-items: center; /* 垂直方向の中央寄せ */
    z-index: 999; /* フッターを最前面に表示 */
    position: fixed; /* フッターを固定位置にします。スクロールしても画面下部に固定されます。 */
}
