.hamburger-menu-button {
  position: relative;
  cursor: pointer;
  width: 6.25rem; /* 100px */
  height: 6.25rem; /* 100px */
  border-radius: 0.625rem; /* 10px */
  background-color: inherit;
  z-index: 998;
  margin-top: 1.5rem; /* 24px */
  margin-right: 0.625rem; /* 10px */

  span {
    display: inline-block;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 1.75rem; /* 28px */
    width: 45%;
    height: 0.25rem; /* 4px */
    border-radius: 0.625rem; /* 10px */
    background-color: var(--background-color);

    &:nth-last-of-type(1) {
      top: 1.625rem; /* 26px */
    }

    &:nth-last-of-type(2) {
      top: 2.375rem; /* 38px */
    }

    &:nth-last-of-type(3) {
      top: 3.125rem; /* 50px */

      &::after {
        position: absolute;
        top: 0.625rem; /* 10px */
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0.625rem; /* 10px */
        background-color: var(--background-color);
        transition: all .3s ease-in-out;
      }
    }
  }

  &.active {
    span {
      &:nth-last-of-type(1) {
        top: 1.75rem; /* 28px */
        left: 2.25rem; /* 36px */
        transform: translateY(0.75rem) rotate(-45deg); /* 12px */
        width: 30%;
      }

      &:nth-last-of-type(2) {
        opacity: 0;
      }

      &:nth-last-of-type(3) {
        top: 3.25rem; /* 52px */
        left: 2.25rem; /* 36px */
        transform: translateY(-0.75rem) rotate(45deg); /* -12px */
        width: 30%;

        &::after {
          top: 0.625rem; /* 10px */
          left: 10%;
          transform: translateX(-50%) translateY(0) rotate(-45deg);
        }
      }
    }
  }
}