.main-slider {
  width: 30%;

  @media (max-width: 768px) {
    width: 60%;
  }

  margin: 0 auto; /* 中央に配置するため */
  position: relative; /* 矢印の位置を調整するため */
  
  .thumbs {
    margin: 0 5px; /* サムネイル間のスペースを調整 */
    justify-content: center; /* サムネイルのリストを中央に配置 */
    padding: 0;
  }
}


.president-message {
  .ceo-photo {
    width: 300px; /* 画像の幅を調整 */
    height: 300px; /* 画像の高さを調整 */
    border-radius: 50%; /* 丸く切り抜く */
    object-fit: cover; /* 画像を中央にフィットさせる */
    display: block;
    margin: 0 auto; /* 画像を中央に配置 */
  }
  .ceo-quote {
    animation: fadeIn 1s ease-in-out;
    display: block; /* 要素をブロックにして中央寄せ */
    font-style: italic; /* 斜体 */
    text-align: center; /* 中央寄せ */
    font-weight: bold;
    width: 300px;
    margin: 20px auto; /* 上下のマージンを20px、左右のマージンを自動で中央寄せ */
    padding: 20px; /* 内側の余白を追加 */
    border: 2px solid var(--ceo-primary-color); /* 四角で囲む */
    border-radius: 10px; /* 角を丸くする */
    background-color: var(--ceo-background-color); /* 背景色を追加 */
  }
}

