.custom-header {
  display: flex;
  align-items: center;
  background-color: var(--header-background-color);
  color: var(--text-light-color);
  position: fixed;
  width: 100%;
  height: 5rem;
  z-index: 999;
  top: 0;

  h1 {
    font-size: 2.5vw;

    @media (max-width: 768px) {
      font-size: 4vw;
    }

    @media (min-width: 1920px) {
      font-size: 1.5vw;
    }

    font-weight: bold;
    text-align: left;
    margin-right: 0.05vw;

    white-space: nowrap;
  }

  nav {
    justify-content: space-around;
    margin-left: auto;
    margin-right: 0.05vw;

    ul {
      padding: 0;
      margin: 0;
      overflow-x: auto;
      white-space: nowrap;

      li {
        list-style: none;
        display: inline-block;
        margin-right: 1vw;

        a {
          text-decoration: none;
          color: white;
          transition: all 0.2s;
          font-weight: bold;
          font-size: 2vw;

          @media (min-width: 1920px) {
            font-size: 1.5vw;
          }

          &:hover {
            color: var(--text-hover-color);
            font-size: 1.9vw;
          }
        }
      }
    }
  }
}
