:root {
  --text-primary-color: #4b4b4b;
  --text-secondary-color: #34495e;
  --text-light-color: #ffffff;
  --text-h1-color: #7ac3f3;
  --text-h1-border-color: #7ac3f3;
  --text-h2-border-color: #ecf0f1;
  --text-hover-color: #87bfe4;
  --text-productheader-color: #3498db;
  
  --background-color: #ffffff;
  --background-dark-color: #414141;

  --button-primary-color: #3498db;
  --button-hover-color: #5098db;
  
  --ceo-primary-color: #7ac3f3;
  --ceo-background-color: #fafafa;

  --table-border-color: #ccc;
  --table-background-color: #ffffff;
  --table-title-background-color: #f0f0f0;

  --header-background-color: #4b6baad5;
  --header-background-rightmenu-color: #4b6baad5;
}

.App {
  font-family: "游ゴシック","ヒラギノ角ゴ ProN",sans-serif;
  width: 95%;
  height: 100vh;
  font-size: 18px;
}

.app-routes {
  text-align: left;
  padding-left: 2rem;
  padding-bottom: 3rem;
  color: var(--text-primary-color);
  background-color: var(--background-color);

  h1 {
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--text-h1-color);
    text-align: center;
    border-bottom: 0.1875rem solid var(--text-h1-border-color);
  }

  h2 {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--text-secondary-color);
    border-bottom: 0.125rem solid var(--text-h2-border-color);
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    color: var(--text-secondary-color);
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    color: var(--text-primary-color);
  }

  address {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }

  table {
    width: 95%;
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border-collapse: collapse;
    
    td {
      border: 0.0625rem solid var(--table-border-color);
      padding: 0.5rem;
      background-color: var(--table-background-color);

      &:first-child {
        font-weight: bold;
        background-color: var(--table-title-background-color);
      }
    }
  }
}

.map-button {
  width: 50%; /* デフォルトではボタンの幅を50%に設定 */
  @media (max-width: 768px) {
    width: auto; /* スマホサイズの時はボタンの幅を文字に応じて自動調整 */
  }
  margin: 0 auto; /* ボタンを水平中央に配置 */
  display: flex; /* フレックスボックスを使用 */
  align-items: center; /* フレックスアイテムを垂直方向に中央揃え */
  justify-content: center; /* フレックスアイテムを水平方向に中央揃え */
  padding: 10px 20px; /* ボタンの内側の余白を設定 */
  background-color: var(--button-primary-color); /* ボタンの背景色を設定 */
  color: var(--text-light-color); /* ボタンの文字色を白に設定 */
  text-decoration: none; /* テキストの装飾をなしに設定 */
  border-radius: 5px; /* ボタンの角を丸くする */
  transition: background-color 0.3s ease; /* 背景色の変化にアニメーションを追加 */
  margin: 0; /* リンク自体のマージンをリセット */
  text-align: center; /* テキストを中央揃えに設定 */

  &:hover {
    background-color: var(--button-hover-color); /* ホバー時の背景色を設定 */
    transform: scale(0.95); /* ホバー時にボタンを少し縮小 */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transform: translateY(1.25rem);
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}
