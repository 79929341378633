.right-menu {
    aside {
        animation: fadeRightAnime 0.3s forwards;
        position: fixed;  /* Body部分に重ねるためにfixedを使用 */
        top: 0;  /* 縦位置を最上部に設定 */
        right: 0;  /* 横位置を最右部に設定 */
        width: 50%;
        height: 100%;  /* 縦幅を100%に設定 */
        background-color: var(--header-background-rightmenu-color);
        z-index: 998;  /* Body部分よりも手前に表示 */

        &.hidden {
            animation: slideRightAnime 0.3s forwards;
        }

        ul {
            li {
                list-style: none;
                display: inline-block;
                margin: 1.5rem 0 0;
                margin-left: -1.5rem;
                
                a {
                    text-decoration: none;
                    color: var(--text-light-color);
                    transition: all 0.2s;
                    font-size: 28px;

                    &:hover {
                        color: var(--text-hover-color);
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

@keyframes fadeRightAnime {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRightAnime {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
